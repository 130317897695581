// src/plugins/sentryPlugin.js
import * as Sentry from '@sentry/vue'
import { watch } from 'vue'
import { usePage } from '@inertiajs/vue3'
import type { Core } from '@/types/generated.js'

export default {
  install(app) {
    const page = usePage<Core.InertiaPageProps>()

    // try to get other authenticables and set them here!?
    const updateUser = () => {
      const user = page.props?.auth?.user

      if (user) {
        Sentry.setUser({ email: user.email, id: user.id, name: user.name })
      } else {
        Sentry.setUser(null) // or clear the user
      }
    }

    // Watch for changes in the user state and update Sentry
    watch(
      () => page?.props,
      () => {
        updateUser()
      },
      { immediate: true }
    )

    const sentryRelease =
      document.querySelector('meta[name="app-release"]')?.getAttribute('content') ?? null

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.VITE_APP_ENV,
      integrations: [Sentry.replayIntegration()],
      release: sentryRelease,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 0.01,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 1.0,
    })

    // Initial user setup
    updateUser()
  },
}
